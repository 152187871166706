import * as bootstrap from "bootstrap";
// import "lite-youtube-embed";
import svgCheck from "bundle-text:./images/icons/check-lg.svg";
import vehicleMakes from "./vehicleMakes.json";
// import "@popperjs/core";
// import "bootstrap-icons/font/bootstrap-icons.css";
// import "/src/scss/custom.scss";
// import "@fortawesome/fontawesome-free";

// URL QUERY PARAMS
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

// AB Tests
// (() => {
//     if (!urlParams.has("newfields")) {
//         const yearInput = document.getElementById("yearInput");
//         const mileageInput = document.getElementById("mileageInput");
//         const phoneInput = document.getElementById("phoneInput");
//         const zipCodeInput = document.getElementById("zipCodeInput");
//         const textInputs = [yearInput, mileageInput, zipCodeInput];
//         const allInputs = [yearInput, mileageInput, phoneInput, zipCodeInput];
//         textInputs.forEach(input => input.setAttribute("type", "text"));
//         allInputs.forEach(input => input.removeAttribute("inputmode"));
//     }
// })();


// HOME HEADER
if (urlParams.has("h1")) {
    const homeHeader = document.getElementById("homeHeader");
    homeHeader.textContent = urlParams.get("h1");
}


// DONATION FORMS
// Initialize tooltip
const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
// Form initialization
const donationFormCarousel = document.getElementById("donationFormCarousel");
if (donationFormCarousel) {
    const donationCarousel = new bootstrap.Carousel(donationFormCarousel, {
        interval: false,
        keyboard: false,
        pause: false,
        ride: false,
        touch: false,
        wrap: false
    });

    // form make selection list functions
    function emptyCombo(selectElement) {
        selectElement.options.length = 0;
        return selectElement;
    };

    function populateCombo(selectElement, items) {
        return appendChildren(selectElement, items.map(item => new Option(item, item)));
    };

    function appendChildren(element, children) {
        element.appendChild(new Option("Choose...", "", true, false));
        children.forEach(child => element.appendChild(child));
        return element;
    };

    // Header Functions
    const donationFormHeader = document.getElementById("donationFormHeader");

    // change donation form tab icon and make field reset
    function toggleHidden(elementList) {
        for (const element of elementList) {
            element.classList.toggle("d-none");
        }
    };

    // change tab image color, and return to step 1
    donationFormHeader.addEventListener("shown.bs.tab", event => {
        const newTab = event.target; // newly activated tab
        const prevTab = event.relatedTarget; // previous active tab
        const currentImgs = newTab.getElementsByTagName("img");
        toggleHidden(currentImgs);
        const previousImgs = prevTab.getElementsByTagName("img");
        toggleHidden(previousImgs);
        // return to step 1
        donationCarousel.to(0);
        // set new options for makes
        populateCombo(emptyCombo(document.getElementById("makeInput")), vehicleMakes[newTab.dataset.makeOptions]);
    });

    // Form step button actions
    // progress bar and thank you page
    const thankYouHeader = document.getElementById("thankYouHeader");
    const donationFormProgressBar = document.getElementById("donationFormProgressBar");
    const donationFormStep2Circle = document.getElementById("donationFormStep2Circle");
    donationFormCarousel.addEventListener("slide.bs.carousel", event => {
        donationFormProgressBar.classList.toggle("w-100");
        donationFormStep2Circle.classList.toggle("bg-charity-yellow");
        donationFormStep2Circle.classList.toggle("text-white");
        // window.history.pushState({ "step": event.to }, "", `#step${event.to + 1}`);
        // console.log(window.history);
        // Final step Thank You
        if (event.relatedTarget.id === "stepThankYou") {
            document.getElementById("donationFormStepIndicators").classList.add("d-none");
            document.getElementById("donationFormHeader").classList.add("d-none");
        }
    });

    // Google Event
    // function gtag_report_conversion(url) {
    //     var callback = function () {
    //         if (typeof (url) != 'undefined') {
    //             window.location = url;
    //         }
    //     };
    //     gtag('event', 'conversion', {
    //         'send_to': 'AW-860465365/aCNCCPD1u4ABENXRppoD',
    //         'event_callback': callback
    //     });
    //     return false;
    // }

    // Form Validation
    // Step 1
    const donationFormStep1 = document.getElementById("step1");
    const step1DonateButton = document.getElementById("step1DonateButton");
    step1DonateButton.addEventListener("click", event => {
        if (!donationFormStep1.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            donationCarousel.next();
        }
        donationFormStep1.classList.add("was-validated");
    });
    // Step 2
    const donationFormStep2 = document.getElementById("step2");
    const step2DonateButton = document.getElementById("step2DonateButton");
    step2DonateButton.addEventListener("click", event => {
        if (!donationFormStep2.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            const formData1 = new FormData(donationFormStep1);
            const formData2 = new FormData(donationFormStep2);
            const submitData = {
                ...Object.fromEntries(formData1),
                ...Object.fromEntries(formData2),
                ...Object.fromEntries(urlParams)
            };
            fetch("https://u0kh4bz7m2.execute-api.us-east-2.amazonaws.com/prod/donations", {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "X-Api-Key": "RuDLIHEa608vK6K8yJjpy8E0AAUAnWJQaYwp8eKM"
                },
                body: JSON.stringify(submitData),
            })
                .then(response => response.json())
                .then(data => {
                    console.log("Success:", data);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
            thankYouHeader.textContent = submitData.firstName + ", " + thankYouHeader.textContent;
            donationCarousel.next();
            // Event snippet for Form Submission conversion page
            gtag('event', 'conversion', { 'send_to': 'AW-10950612297/gahJCOyPj9UDEMmq1OUo' });
            // Event snipper for Form Submission Bing
            window.uetq = window.uetq || []; window.uetq.push('event', 'contact', {});
        }
        donationFormStep2.classList.add("was-validated");
    });
}

// Contact Us Form
const contactUsForm = document.getElementById("contactUsForm");
if (contactUsForm) {
    const contactUsFormButton = document.getElementById("contactUsFormButton");

    contactUsFormButton.addEventListener("click", event => {
        console.log("clicked");
        if (!contactUsForm.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            contactUsFormButton.toggleAttribute("disabled", true);
            contactUsFormButton.innerHTML = '<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Sending...';
            const contactUsFormData = new FormData(contactUsForm);
            const submitData = {
                ...Object.fromEntries(contactUsFormData)
            }
            fetch("https://u0kh4bz7m2.execute-api.us-east-2.amazonaws.com/prod/contact", {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "X-Api-Key": "RuDLIHEa608vK6K8yJjpy8E0AAUAnWJQaYwp8eKM"
                },
                body: JSON.stringify(submitData),
            })
                .then(response => response.json())
                .then(data => {
                    console.log("Success:", data);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
            contactUsFormButton.innerHTML = svgCheck + " Sent";
        }
        contactUsForm.classList.add("was-validated");
    });
}
